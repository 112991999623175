/**
 * Created by xiongxiaomeng.
 * 项目扩展信息相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    info: params => {  return API.POST('projectext/info', params)},
    list: params => {  return API.GET('projectext/list', params)},
    get: params => {  return API.GET('projectext/get', params)},
    delete: params => {  return API.DELETE('projectext/delete', params)}
}
