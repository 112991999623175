<template>
  <div class="box-card">
    <el-form :model="proExtInfo" ref="proExtInfo" :rules="editRules" class="demo-ruleForm">
      <el-row>
        <el-col :span="6">
          <el-form-item label="冷库是否规格" label-width="180px">
              <el-radio v-model="proExtInfo.stdshapeflag" label=1>是</el-radio>
              <el-radio v-model="proExtInfo.stdshapeflag" label=0>否</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="长" label-width="180px" prop="length">
            <el-input v-model="proExtInfo.length"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="宽" label-width="180px" prop="width">
            <el-input  v-model="proExtInfo.width"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="高" label-width="180px" prop="height">
            <el-input  v-model="proExtInfo.height"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="体积" label-width="180px" prop="volume">
            <el-input v-model="proExtInfo.volume"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备个数" label-width="180px" prop="deviceqty">
            <el-input v-model="proExtInfo.deviceqty"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备原始最低温度设定" label-width="180px" prop="devicetemperminorgset">
            <el-input  v-model="proExtInfo.devicetemperminorgset"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备原始最高温度设定" label-width="180px" prop="devicetempermaxorgset">
            <el-input  v-model="proExtInfo.devicetempermaxorgset"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="设备调整后最低温度设定" label-width="180px" prop="devicetemperminfinalset">
            <el-input v-model="proExtInfo.devicetemperminfinalset"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备调整后最高温度设定" label-width="180px" prop="devicetempermaxfinalset">
            <el-input v-model="proExtInfo.devicetempermaxfinalset"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="备用设备个数" label-width="180px" prop="devicebackupqty">
            <el-input  v-model="proExtInfo.devicebackupqty"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save('proExtInfo')">确 定</el-button>
      </div>
  </div>
</template>

<script>
import PMExt from '../../api/biz/api_projectext';
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";
import USER from "@/api/base/api_user";

export default {
  name: "projectextinfo",
  props: {
    // proExtInfo: {
    //   type: Object
    // }
    projectInfo: {
      type: Object
    }
  },
  data() {
    return {
      proExtInfo: {},
      editRules: {
        name: [{required: true, message: '请输入客户名称', trigger: 'blur'}, {
          min: 2,
          max: 25,
          message: '长度在 2 到 25 个字符',
          trigger: 'blur'
        }],
        type: [{required: true, message: '请选择客户类型！', trigger: 'blur'}]
      },
    }
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.initPageData();
    },

    // 初始化新增数据
    initAddData() {
      this.proExtInfo = {
        pmid: this.$route.params.pmid,
        stdshapeflag: 0,
        length: 0,
        width: 0,
        height: 0,
        volume: 0,
        deviceqty: 0,
        devicetemperminorgset:0,
        devicetempermaxorgset:0,
        devicetemperminfinalset:0,
        devicetempermaxfinalset:0,
        devicebackupqty:0
      }
    },

    // 加载页面数据
    initPageData() {
      //this.showLoading = true;
      let pmid = this.$route.params.pmid;
      if (pmid == 'null' || pmid == null || pmid == '') {
        return;
      }

      // 执行查询
      PMExt.get({
        pmid: this.$route.params.pmid
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          if (res.data == undefined || res.data == null) {
            this.initAddData();
          } else {
            this.proExtInfo = res.data;
          }
        } else {
          this.$message.error(res.msg);
          this.initAddData();
        }
      });
    },

    // 保存数据
    save(formName) {
      if (this.proExtInfo.pmid == null) {
        this.proExtInfo.pmid = this.projectInfo.id;
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          PMExt.info(this.proExtInfo).then((res) => {
            if (200 == res.code) {
              this.$message.success("操作成功！");
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>